body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Supreme";
  src: url("./fonts/Supreme.ttf") format("truetype");
}

@font-face {
  font-family: "Narnia";
  src: url("./fonts/Narnia.ttf") format("truetype");
}

@font-face {
  font-family: "Ceratyn";
  src: url("./fonts/Ceratyn.ttf") format("truetype");
}

@font-face {
  font-family: "Office";
  src: url("./fonts/Office.otf") format("opentype");
}

@font-face {
  font-family: "Sentient";
  src: url("./fonts/Sentient.ttf") format("truetype");
}
