/* =================
    Base Styles 
    ================= */
body {
  margin: 0;
  padding: 0;
  background-color: #ff1493;
}

/* =================
    Layout Components 
    ================= */
.App {
  text-align: center;
  position: relative;
}

.hero-section {
  height: 100vh;
  position: relative;
  background-color: black;
  overflow: hidden;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
  z-index: 3;
}

.App-link {
  color: #61dafb;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.mask {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  mix-blend-mode: screen;
}

/* .mask-athens {
  background: brown;
} */

.mask-athens {
  background: #7393b3;
}

.logo-container {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* =================
    Folder Section 
    ================= */
.folder-section {
  background-color: black;
  min-height: 100vh;
  padding: 20px;
}

.folder-list {
  color: #faf9f6;
  font-family: "Office", Monaco, Consolas, monospace;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.folder-list-header {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 1fr;
  padding: 10px 20px;
  border-bottom: 1px solid #333;
  font-size: 14px;
  color: #666;
}

.folder-item {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 1fr;
  padding: 10px 20px;
  border-bottom: 1px solid #333;
  cursor: pointer;
  font-size: 14px;
}

.folder-item > span:not(.name-column) {
  font-size: 14px;
  color: #666;
}

.folder-item:hover {
  background-color: #111;
}

.folder-item:hover svg,
.folder-item:hover span {
  color: #ffeb80;
  opacity: 0.8;
}

.folder-item:hover .content-icon {
  color: #ffeb80;
}

.folder-item:hover .stock-performance-button {
  color: #ffeb80 !important;
  border-color: #ffeb80 !important;
  opacity: 0.8;
}

.name-column {
  display: flex;
  align-items: center;
  gap: 12px;
}

.folder-icon {
  display: flex;
  align-items: center;
}

.folder-item svg {
  color: #666;
}

/* IMPORTANT: row text font size */
.nested-item {
  font-size: 28px;
}

/* =================
    Links and Interactive Elements 
    ================= */
.company-link {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

.company-link:hover {
  opacity: 0.7;
}

/* =================
    Audio Player Components 
    ================= */
.song-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 10px 20px;
  border-bottom: 1px solid #333;
  align-items: center;
}

.player-column {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-left: -8px;
}

.play-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button:hover {
  background: #222;
  color: #ffeb80;
}

.progress-bar-container {
  flex: 1;
  height: 4px;
  background: #333;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
}

.progress-bar-container:hover {
  height: 6px;
}

.progress-bar {
  height: 100%;
  background: #666;
  transition: width 0.1s linear;
}

.song-item:hover .progress-bar {
  background: #ffeb80;
}

.folder-item:hover .progress-bar,
.folder-item:hover .progress-bar-container {
  background: #ffeb80;
}

.folder-item:hover .progress-bar-container {
  background: rgba(255, 235, 128, 0.2);
}

/* =================
    Content Modal Components 
    ================= */
.content-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content-modal {
  position: relative;
  max-width: 35vw;
  max-height: 35vh;
}

.content-modal img,
.content-modal video {
  max-width: 100%;
  max-height: 35vh;
  object-fit: contain;
}

.close-button {
  position: absolute;
  right: -40px;
  background: none;
  border: none;
  color: #faf9f6;
  cursor: pointer;
  padding: 8px;
}

.close-button:hover {
  color: #ffeb80;
}

.content-icon {
  display: flex;
  align-items: center;
  color: #666;
}

/* =================
    Scroll Down Button
    ================= */
.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scroll-button {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  color: white;
  cursor: pointer;
  padding: 15px;
  z-index: 10;
  opacity: 1;
  transition: all 0.3s ease;
}
.scroll-button:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%) scale(1.1);
}

/* =================
    Mobile Responsive Styles 
    ================= */
@media screen and (max-width: 768px) {
  .scroll-button {
    display: block;
  }

  .folder-list-header,
  .folder-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .folder-list-header span:nth-child(2),
  .folder-list-header span:nth-child(3),
  .folder-item span:nth-child(2),
  .folder-item span:nth-child(3) {
    display: none;
  }

  .folder-item span:last-child {
    display: block;
  }

  .player-column {
    padding-left: 16px;
    margin-right: 16px;
  }
}

/* =================
    Circular Text
    ================= */
.circular-text-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 10;
  overflow: visible;
}

.circular-text {
  font-family: "Ceratyn", Monaco, monospace;
  font-size: 10px;
  fill: rgba(102, 102, 102, 0.3);
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* Add this to your existing circular text styles */
.circular-text tspan {
  transition: fill 0.3s ease;
}

.rotating {
  animation: rotate 100s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =================
    Mobile Warning
    ================= */
.mobile-warning {
  display: none;
  position: fixed;
  top: 55%; /* moved down from 50% */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  padding: 20px;
  font-family: "Office", Monaco, monospace;
  color: #ff1493;
  pointer-events: none;
  font-size: 24px; /* added font size */
}

@media screen and (max-width: 768px) {
  .mobile-warning {
    display: block;
  }
}

/* =================
    Top Audio Player
    ================= */
.top-audio-player {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  opacity: 0.6;
}

.player-controls {
  display: flex;
  gap: 8px;
  background: rgba(0, 0, 0, 0.3); /* Lower opacity */
  padding: 6px 12px;
  border-radius: 20px;
  backdrop-filter: blur(5px);
}

.player-button {
  background: none;
  border: none;
  color: #faf9f6;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  transition: all 0.2s ease;
}

.player-button:hover {
  color: #ffeb80;
  transform: scale(1.1);
}

.song-title {
  font-family: "Office", Monaco, monospace;
  font-size: 12px;
  color: #faf9f6;
  margin-top: 6px;
  background: rgba(0, 0, 0, 0.3); /* Lower opacity */
  padding: 4px 10px;
  border-radius: 12px;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make sure it's visible on mobile too */
@media screen and (max-width: 768px) {
  .top-audio-player {
    top: 10px;
  }
}
